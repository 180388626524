function closeModalonKey() {
    document.addEventListener("keyup", function (eventEsc) {
        if (eventEsc.which == 27) {
            $(".js-modal").fadeOut(350);
            $(".js-modal-two").fadeOut(350);
            $(".js-slider-main").fadeOut(350);
            $(".js-slider-main").removeClass('active');
            $('.js-body').removeClass('active');
        }
    });
}

function uiSlider() {
    slider.noUiSlider.on('update', function () {
        if (($.trim($('.js-separated').val()).length == 0) && ($('.js-calc-bgn').is(':checked'))) {
            $('.js-output').val('0,00 лв.');
        } else if (($.trim($('.js-separated').val()).length == 0) && ($('.js-calc-eur').is(':checked'))) {
            $('.js-output').val('€ 0,00');
        }
    });
}

function offsettop() {
    $('.js-scroll').click(function (a) {
        $(this).removeClass('active');
        var datascroll = $(this).data('scroll');
        a.preventDefault();
        $('html, body').animate({
            scrollTop: $('#' + datascroll).offset().top - 100
        }, 1000);
    });
}

function loadRow() {

    var textTab = $('.js-row'),
        textTabContainer = $('.js-row-container');

    if (textTab.length) {

        textTab.off('click').on('click', function () {
            var textRow = $(this).parent(),
                textContent = $(this).parent().find('.js-row-item');
            textTabContainer.find('.js-row-content').not(textRow).removeClass('active');
            textTabContainer.find('.js-row-item').not(textContent).stop().slideUp('slow');


            textContent.stop().slideToggle('slow', function () {
                textRow.toggleClass('active');
            });
        });
    }


    $('.js-load-row').click(function (n) {
        var dataRow = $(this).data('row');
        n.preventDefault();
        $('.js-row__box').removeClass('active');
        $('#' + dataRow).addClass('active');
    });
}

function toTop() {
    $(window).scroll(function () {
        if ($(this).scrollTop() >= 700) {
            $('.js-up').addClass('btn-up--show');
        } else {
            $('.js-up').removeClass('btn-up--show');
        }
    });

    $('.js-up').click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });
}

$(document).ready(function () {

    if ($('.js-up').length) {
        toTop();
    }

    if ($('.js-scroll').length) {
        offsettop();
    }

    if ($('.js-separated').length) {
        uiSlider();
    }

    if ($('.js-row').length) {
        loadRow();
    }

    if ($('.js-modal').length) {
        closeModalonKey();
    }


    $('.js-burger-menu').on('click', function () {
        $('.burger-menu').toggleClass('burger-menu-active');
        $('.js-header-menu-list').toggleClass('active');
        $('.js-body').toggleClass('active');
    });

    $('.header-menu__list-link').on('click', function () {
        $('.js-body').removeClass('active');
        $('.js-header-menu-list').removeClass('active');
        $('.burger-menu').removeClass('burger-menu-active');
    });

    $(window).resize(function () {
        $('.burger-menu').removeClass('burger-menu-active');
        $('.js-header-menu-list').removeClass('active');
    });

    $('.js-cta-btn').on('click', function () {
        $(".js-modal").fadeIn(350);
        $('.js-body').addClass('active');
    });

    $('.js-cta-btn-two').on('click', function () {
        $(".js-modal-two").fadeIn(350);
        $('.js-body').addClass('active');
        // $('html').addClass('active');
    });

    $('.js-modal-close').on('click', function () {
        $(".js-modal").fadeOut(350);
        $(".js-modal-two").fadeOut(350);
        $(".js-slider-main").fadeOut(350);
        $(".js-slider-main").removeClass('active');
        $('.js-body').removeClass('active');
        // $('html').removeClass('active');
    });

    $('.js-slider-open').on('click', function () {
        $(".js-slider-main").fadeIn(350);
        $(".js-slider-main").addClass('active');
        $(window).trigger('resize');
    });

    $('.tooltip').tooltipster({
        animation: 'fade',
        theme: ['tooltipster-noir', 'tooltipster-noir-customized'],
        trigger: 'ontouchstart' in window || navigator.maxTouchPoints ? 'click' : 'hover',
        interactive: true,
        contentAsHTML: true,
    });

    // $('.tooltip').tooltipster('open');

    $('.js-container').on('click', function () {
        $(this).closest('.select2-container').find('.select2-selection').addClass('active');
    });


    $('.js-input').each(function () {
        if ($(this).hasClass('error')) {
            $(this).closest('.js-container').find('.js-error-message').addClass('active');
            $(this).addClass('mb-15');
        }
    });

    // $(document).mouseup(function (e) {
    //     var container = $(".slider-inner");
    //     var prev = $(".slick-prev");
    //     var next = $(".slick-next");
    //     if (!container.is(e.target) && container.has(e.target).length === 0 && !prev.is(e.target) && prev.has(e.target).length === 0 && !next.is(e.target) && next.has(e.target).length === 0) {
    //         $(".js-slider-main").fadeOut();
    //         $(".js-slider-main").removeClass('active');
    //     }
    // });

    // $(document).mouseup(function (e) {
    //     var containertwo = $(".modal-content");
    //     if (!containertwo.is(e.target) && containertwo.has(e.target).length === 0) {
    //         $(".js-modal").fadeOut();
    //         $(".js-modal-two").fadeOut();
    //         $('.js-body').removeClass('active');
    //         $('.js-burger-menu').removeClass('burger-menu-active');
    //         $('.js-header-menu-list').removeClass('active');
    //         // $('html').removeClass('active');
    //     }
    // });

    $(".loader:visible").each(function () {
        $('body').addClass('active');
    });


    $('.js-select').each(function () {
        var thisParent = $(this).parent();
        var thisSelect = $(this);
        var dataPlaceholder = thisSelect.attr('data-placeholder');
        thisSelect.select2({
            theme: 'dbank',
            dropdownParent: thisParent,
            minimumResultsForSearch: 6,
            placeholder: dataPlaceholder,
        });
    });

    $('#select-9').change(function () {
        var pinValue = $('.js-get-number').val();
        if (typeof $('#select-9 option:selected').attr('data-copy-pin') !== 'undefined') {
            $('.js-paste-number').val(pinValue)
        } else {
            $('.js-paste-number').val('');
        }
    });

    $('.js-calculator-form').on('submit', function (e) {
        var formvalues = $(this).serialize();
    });

    $('.js-form').on('submit', function (f) {
        $(".js-group").next('#space').remove();
        var formvalues = $(this).serialize();
    });

    $(".js-check-one").click(function () {
        var group = "input:checkbox[name='" + $(this).attr("name") + "']";
        $(group).prop("checked", false);
        $(this).prop("checked", true);
    });

    $(document).ready(function () {
        if ($('.js-calc-bgn').is(':checked')) {
            $('.js-eur-serialize').val('0');
        } else if ($('.js-calc-eur').is(':checked')) {
            $('.js-bgn-serialize').val('0');
        }
    });


    $('.js-calc-bgn').change(function () {
        if ($(this).is(':checked'))
            $('.js-future-value').val('0,00 лв.');
        $('.js-eur-serialize').val('0');
        $('.js-output').val('0,00 лв.');
        $('.js-input').val('');
    });

    $('.js-calc-eur').change(function () {
        if ($(this).is(':checked'))
            $('.js-output').val('€ 0,00');
        $('.js-bgn-serialize').val('0');
        $('.js-future-value').val('€ 0,00');
        $('.js-input').val('');
    });

    $(document).ready(function () {
        if ($('.js-input-07-yes').is(':checked')) {
            $('.js-form-input-03').addClass('active');
            $('.js-input-13').attr('required', true);
        } else {
            $('.js-form-input-03').removeClass('active');
            $('.js-input-13').removeAttr('required');
        }
    });

    $('.js-separated').on('keyup', function () {
        if (($.trim($('.js-separated').val()).length == 0) && ($('.js-calc-bgn').is(':checked'))) {
            $('.js-output').val('0,00 лв.');
        } else if (($.trim($('.js-separated').val()).length == 0) && ($('.js-calc-eur').is(':checked'))) {
            $('.js-output').val('€ 0,00');
        }
    });

    $('.js-input-07').change(function () {
        $('.js-input-07').each(function () {
            if ($(this).is(':checked')) {
                $('.js-form-input-03').removeClass('active');
                $('.js-input-13').removeAttr('required');
            } else {
                $('.js-form-input-03').addClass('active');
                $('.js-input-13').attr('required', true);
            }
        });
    });


    $(document).ready(function () {
        if ($('.js-zmip-yes').is(':checked')) {
            $('.js-form-input-02').addClass('active');
            $('.js-input-06').attr('required', true);
        } else if ($('.js-zmip-no').is(':checked')) {
            $('.js-form-input-02').removeClass('active');
            $('.js-input-06').removeAttr('required');
        }
    });

    $(document).ready(function () {
        if ($('.js-gov-yes').is(':checked')) {
            $('.js-form-input-05').addClass('active');
            $('.js-input-15').attr('required', true);
        } else if ($('.js-gov-no').is(':checked')) {
            $('.js-form-input-05').removeClass('active');
            $('.js-input-15').removeAttr('required');
        }
    });

    $('.js-input-05').change(function () {
        $('.js-input-05').each(function () {
            if ($(this).is(':checked')) {
                $('.js-form-input-02').removeClass('active');
                $('.js-input-06').removeAttr('required');
            } else {
                $('.js-form-input-02').addClass('active');
                $('.js-input-06').attr('required', true);
            }
        });
    });

    $('.js-input-14').change(function () {
        $('.js-input-14').each(function () {
            if ($(this).is(':checked')) {
                $('.js-form-input-05').removeClass('active');
                $('.js-input-15').removeAttr('required');

            } else {
                $('.js-form-input-05').addClass('active');
                $('.js-input-15').attr('required', true);

            }
        });
    });
    $(document).ready(function () {
        if ($('.js-input-08-yes').is(':checked')) {
            $('.js-form-input').addClass('active');
            $('.js-input-04').attr('required', true);

        } else {
            $('.js-form-input').removeClass('active');
            $('.js-input-04').removeAttr('required');
        }
    });
    $('.js-input-08').change(function () {
        $('.js-input-08').each(function () {
            if ($(this).is(':checked')) {
                $('.js-form-input').removeClass('active');
                $('.js-input-04').removeAttr('required');
            } else {
                $('.js-form-input').addClass('active');
                $('.js-input-04').attr('required', true);
            }
        });
    });
    $(document).ready(function () {
        if ($('.js-input-01').is(':checked')) {
            $('.js-form-input-02').toggleClass('active');
        } else
            $('.js-form-input-02').removeClass('active');
    });
    $('.js-input-01').change(function () {
        if ($(this).is(':checked'))
            $('.js-form-input-02').toggleClass('active');
        else
            $('.js-form-input-02').removeClass('active');
    });
    $(document).ready(function () {
        if ($('.js-input-02').is(':checked')) {
            $('.js-form-input-03').toggleClass('active');
        } else
            $('.js-form-input-03').removeClass('active');
    });
    $('.js-input-02').change(function () {
        if ($(this).is(':checked'))
            $('.js-form-input-03').toggleClass('active');
        else
            $('.js-form-input-03').removeClass('active');
    });
    $(document).ready(function () {
        if ($('.js-input-09').is(':checked'))
            $('.js-input-10').attr('required', true);
        else
            $('.js-input-10').removeAttr('required');
    });
    $('.js-input-09').change(function () {
        if ($(this).is(':checked'))
            $('.js-input-10').attr('required', true);
        else
            $('.js-input-10').removeAttr('required');
    });

    $('.js-input-01').change(function () {
        if ($(this).is(':checked'))
            $('.js-input-11').attr('required', true);
        else
            $('.js-input-11').removeAttr('required');
    });
    $(document).ready(function () {
        if ($('.js-input-09').is(':checked')) {
            $('.js-form-input-04').toggleClass('active');
        } else
            $('.js-form-input-04').removeClass('active');
    });
    $('.js-input-09').change(function () {
        if ($(this).is(':checked'))
            $('.js-form-input-04').toggleClass('active');
        else
            $('.js-form-input-04').removeClass('active');
    });

    $('.js-check').change(function () {
        if ($(this).is(':checked'))
            $('.js-form-input-03').removeClass('active');
    });

    $(function () {
        $("#datepicker").datepicker({
            dateFormat: 'yy-mm-dd',
            minDate: 0,
        });
    });

    // $('.js-zmip-yes').change(function () {
    //     if ($(".js-input-05").is(':checked')) {
    //         $('.js-input-06').attr('required', true);
    //     } else {
    //         $('.js-input-06').removeAttr('required');
    //     }
    // });

    $('.js-income').change(function () {
        if ($(".js-input-02").is(':checked')) {
            $('.js-input-03').attr('required', true);
        } else {
            $('.js-input-03').removeAttr('required');
        }
    });

    $('#eur').change(function () {
        if ($(this).is(":checked")) {
            $('.js-info-text').addClass("eur");
            $('.js-info-text').removeClass("bgn");
        }
    });

    $('#bgn').change(function () {
        if ($(this).is(":checked")) {
            $('.js-info-text').addClass("bgn");
            $('.js-info-text').removeClass("eur");
        }
    });

    var $status = $('.js-pagingInfo');
    var $slickElement = $('.js-slider');

    $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $status.text(i + '/' + slick.slideCount);
    });

    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        arrows: false,
        focusOnSelect: true
    });

    $('a[data-slide]').click(function (e) {
        e.preventDefault();
        var slideno = $(this).data('slide');
        $('.slider-for').slick('slickGoTo', slideno - 0);
        $(".slider-for").slick("refresh");
        $(".slider-nav").slick("refresh");
    });

    $('.js-submit-btn-scr').click(function () {
        $('html, body').animate({
            scrollTop: $('.js-scroll-to').offset().top
        }, 1000);
    });

    $.validator.addMethod('customphone', function (value, element) {
        return this.optional(element) || /^(\+359)\s*?(?:[1-9]+\s*?(?:\d+\s*?(?:\d+\s*)))(?:[\d|\s]*?)$/.test(value);
    }, $('.js-phone').attr('data-phone-error'));


    $('.js-form').validate({
        errorElement: 'span',
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.js-container').last());
        },
        rules: {
            opt01: {
                required: "#resp01-sim"
            },
            phone: 'customphone',
            minlength: 13,
            maxlength: 13,
        },
        messages: {
            phone: {
                minlength: function () {
                    return [
                        $('.js-phone').attr('data-phone-error'),
                    ];
                },
                maxlength: function () {
                    return [
                        $('.js-phone').attr('data-phone-error'),
                    ];
                },
            }
        },
        onfocusout: function (element) {
            $(element).valid();
        },
    });

    $.validator.addClassRules('customphone', {
        customphone: true
    });

    $('.js-income').each(function () {
        $(this).rules('add', {
            checkone: true
        });
    });

    $('.js-checkbox').change(function () {
        var groupError = $('.js-group').attr('data-group-error');
        var closest = $(this).closest('.js-error-group').find(".js-group");
        if (closest.find('.js-checkbox').is(':checked')) {
            closest.next('#space').remove();
        } else {
            if (closest.next('#space').length > 0) {
                closest.next('#space').remove();
            }
            closest.after('<div id="space" class="mb-20"><span class="error">' + groupError + '</span></div>');
        }
    });

    $.validator.addMethod("checkone", function (e) {
        var groupError = $('.js-group').attr('data-group-error');



        if ($(".js-checkbox").is(":checked")) {
            // $('.js-group').remove('#space');
            return true;
        }
        if ($('#space').length < 1) {
            $('.js-group').after('<div id="space" class="mb-20"><span class="error">' + groupError + '</span></div>');
            return false;
        }
    });

    $.validator.setDefaults({
        ignore: [],
        // any other default options and/or rules
    });

    $('.js-select').on('change', function () {
        $(this).valid();
    });

    //cookie bar
    if ($('.cookie-bar').length) {
        //check for cookie
        if (!readCookie('cookieaccept')) {
            $('.cookie-bar').show();
        }
        //cookie accept
        $('.js-cookie-accept').on('click', function () {
            var date = new Date();
            date.setTime(date.getTime() + (100 * 24 * 60 * 60 * 1000));
            var expires = "expires=" + date.toGMTString();
            document.cookie = 'cookieaccept' + "=true; " + expires + "; path=/";

            $('.cookie-bar').slideUp();
        });
        //cookie only close
        $('.js-cookie-close').on('click', function () {
            $('.cookie-bar').slideUp();
        });
    }
    //cookie
    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return false;
    }

    function checkEgn(egn) {
        if (egn.length !== 10) {
            return false;
        }
        if (/[^0-9]/.test(egn)) {
            return false;
        }
        var year = Number(egn.slice(0, 2));
        var month = Number(egn.slice(2, 4));
        var day = Number(egn.slice(4, 6));

        if (month >= 40) {
            year += 2000;
            month -= 40;
        } else if (month >= 20) {
            year += 1800;
            month -= 20;
        } else {
            year += 1900;
        }

        if (!isValidDate(year, month, day)) {
            return false;
        }

        var checkSum = 0;
        var weights = [2, 4, 8, 5, 10, 9, 7, 3, 6];

        for (var ii = 0; ii < weights.length; ++ii) {
            checkSum += weights[ii] * Number(egn.charAt(ii));
        }

        checkSum %= 11;
        checkSum %= 10;

        if (checkSum !== Number(egn.charAt(9))) {
            return false;
        }

        return true;
    }

    function isValidDate(y, m, d) {
        var date = new Date(y, m - 1, d);
        return date && (date.getMonth() + 1) == m && date.getDate() == Number(d);
    }

    function egnToDate(egn) {
        var y = Number(egn.slice(0, 2)),
            m = Number(egn.slice(2, 4)),
            d = Number(egn.slice(4, 6));

        if (egn.length < 6) {
            return false;
        }

        if (m >= 40) {
            y += 2000;
            m -= 40;
        } else if (m >= 20) {
            y += 1800;
            m -= 20;
        } else {
            y += 1900;
        }

        return new Date(y, m - 1, d);
    }

    $('.js-pin-input').on('focusout', function () {

        if (checkEgn($(this).val().trim()) === false) {
            var pinError = $('.js-pin-input').attr('data-error');
            $('.js-pin-error').html(pinError);
            return false;
        }
        if (checkEgn($(this).val().trim()) === true) {
            $('.js-pin-error').addClass('hidden');
            return true;
        }
    });

    $(".js-separated").inputmask("numeric", {
        min: 0,
        max: 200000,
        alias: 'decimal',
        groupSeparator: ' ',
        autoGroup: true,
        autoUnmask: true,
        digits: 2,
        // placeholder: '0',
        rightAlign: false,
    });

});